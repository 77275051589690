import { inject, observer } from "mobx-react";
import { ApplePayButton, canUseApplePay } from "./ApplePayButton";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const ExpressCheckout = (props = {}) => {
  const [domain, setDomain] = useState();
  const [config] = useState(JSON.parse(window.atob(props.match.params.config)))
  const [errorMessage, setErrorMessage] = useState();
  const [allowedMethods, setAllowedMethods] = useState({
    applePay: false,
    googlePay: false
  });

  const resizeContainer = useCallback(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        window.parent.postMessage(
          {
            event: "callback-payabli-function-resize" + config.randomId,
            data: cr.height,
          },
          "*"
        );
      }
    })
    resizeObserver.observe(document.body);
  }, [config])

  const getListDomains = useCallback(async (paypointID) => {
    const endpoint = `${process.env.REACT_APP_URL_API}PaymentMethodDomain/list?entityId=${paypointID}&entityType=paypoint`;
    return await axios.get(endpoint, {
        headers: {
            requestToken: config.token,
        }
    })
  }, [config]);


  const loadScript = useCallback(
    () =>
      new Promise((resolve) => {
        const script = document.createElement("script");
        script.src =
          "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
        script.type = "text/javascript";
        script.async = true;
        document.head.appendChild(script);
        script.onload = () => {
          resolve();
          console.info("Apple Pay SDK loaded");
        };
      }),
    []
  );

  const getPaypoint = useCallback(
    async (entryname) => {
      const endpoint = `${process.env.REACT_APP_URL_API}Paypoint/basic/${entryname}`;
      return await axios.get(endpoint, {
        headers: {
          requestToken: config.token,
        },
      });
    },
    [config]
  );

  const setTokenUI = useCallback(
    (token) => {
      window.parent.postMessage(
        {
          event: "callback-payabli-function-set-token" + config.randomId,
          data: token,
        },
        "*"
      );
    },
    [config]
  );

  const isApplePayEnabled = useCallback(async (settingsData= {}, tokenData= {}) => {
    // Extract the specific setting for Apple Pay from settingsData
    const applePaySettings = (settingsData.forWallets || []).find(
      (item) => item.key === "isApplePayEnabled"
    );

    // If the setting indicates that Apple Pay is disabled, return false
    if (applePaySettings?.value === "false") {
      return false;
    }

    try {
      // Retrieve the IdPaypoint using tokenData and the entry point
      const { data: { responseData: { Paypoint: { IdPaypoint } } } } = await getPaypoint(tokenData.entryPoint);
      
      // If IdPaypoint is not found, throw an error
      if (!IdPaypoint) {
        throw new Error("Entry ID not found");
      }

      // Retrieve the list of domains associated with the Paypoint
      const { data: { Records: domains } } = await getListDomains(IdPaypoint);

      // Check if the current domain is in the list of domains and applePay is enabled
      const domainFound = domains.filter(domain => domain.applePay.isEnabled).find(d => d.domainName === domain);
      if(!domainFound) console.warn('Domain is not enabled for Apple Pay')
      // If the current domain is not found in the list, return false
      if (!domainFound) return false

      return true;
    } catch (error) {
      console.error("Error in checking Apple Pay availability:", error);
      return false;
    }
    
  }, [getListDomains, getPaypoint, domain]);

  const init = useCallback(
    async ([{ data: tokenData = {} }, { data: settingsData = {} }], config) => {
      new Promise(async (resolve, reject) => {
        const allowedMethods = {}
        try {
          if(!tokenData || !settingsData) {
            throw new Error("Token or settings data not found");
          }
          // Validate if the payment method is enabled
          if (canUseApplePay() && config?.expressCheckout?.applePay?.enabled ) {
            const isApplePayEnabledResult = await isApplePayEnabled(settingsData, tokenData);
            if(isApplePayEnabledResult){
              allowedMethods.applePay = true;
            }
            loadScript()
          } else {
            throw new Error('Apple pay is not supported')   // when add google pay as payment method this 'reject' will be removed
          }

          // Upload custom css
          if (config.customCssUrl) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = config.customCssUrl;
            document.head.appendChild(link);
          }

          // Resize container
          resizeContainer();

          // Update vTerminal
          props.vTerminal.setEntryPoint(tokenData.entryPoint);
          props.vTerminal.setToken(tokenData.identifier);

          // Set token UI
          setTokenUI(tokenData.identifier);
          return resolve(allowedMethods);
        } catch (error) {
          console.error(error);
          setErrorMessage(
            error.message ||
              "An error occurred while initializing the component."
          );
          return reject(error);
        }
      }).then((allowedMethods) => {
        // Send ready event
        window.parent.postMessage(
          {
            event:
              "callback-payabli-function-mounted-component" + config.randomId,
            data: true,
          },
          "*"
        );
        props.global.setLoading(false);
        setAllowedMethods(allowedMethods);
        if(Object.values(allowedMethods).some(method => method)){
          props.global.setReady(true);
          window.parent.postMessage(
            {
              event:
                "callback-payabli-function-expresscheckout-ready" +
                config.randomId,
              data: allowedMethods
            },
            "*"
          );
        }
      })
    },
    [props, setTokenUI, isApplePayEnabled, resizeContainer, loadScript]
  );

  const getTokenAndSettings = useCallback(async () => {
    if (!config?.entryPoint || config?.entryPoint?.length === 0) {
      setErrorMessage("Entry point not found");
      return;
    };
    const murl = `Tools/init/method/${config.entryPoint}`;
    const tokenPromise = axios.get(process.env.REACT_APP_URL_API + murl, {
      headers: {
        requestToken: config.token,
      },
    });
    const settingsPromise = axios.get(
      process.env.REACT_APP_URL_API + `Paypoint/settings/${config.entryPoint}`,
      {
        headers: {
          requestToken: config.token,
        },
      }
    );

    return await Promise.all([tokenPromise, settingsPromise]);
  }, [config]);

  useEffect(() => {
    if(domain) {
      // Check if any service is enabled
      const hasServiceEnabled = Object.values(config.expressCheckout || {}).some(service => service.enabled)
      if(hasServiceEnabled){
        getTokenAndSettings().then((response) => {
          init(response, config);
      });
      }
    }

  }, [config, getTokenAndSettings, init, domain]);

  const handleMessage = useCallback((event) => {
    if (
      event.data.event ===
      `callback-payabli-function-set-domain${config.randomId}`
    ) {
      if (event.data.data) {
        setDomain(event.data.data);
      }
    }
  }, [config])

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    window.parent.postMessage(
      {
        event: "callback-payabli-function-init-domain" + config.randomId,
      },
      "*"
    );
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [config, handleMessage]);

  if (errorMessage) {
    return <p className="small text-center">{errorMessage}</p>;
  }
  

  return (
    <>
      <div
        style={{
          display: "grid",
          position: 'relative',
          gap: '1rem',
          padding: 0,
          maxWidth: 'none',
          gridTemplateColumns: `repeat(${config?.expressCheckout?.columns || 1}, minmax(0, 1fr))`,
        }}
        className="container w-100"
      >
        {allowedMethods.applePay && (
          <ApplePayButton domain={domain} vTerminal={props.vTerminal} config={config} token={props.vTerminal.token} enabled={allowedMethods.applePay} />
        )}
        {/* Here add Google Pay */}
      </div>
    </>
  );
};
export default inject("vTerminal", "global")(observer(ExpressCheckout));
