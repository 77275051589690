import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';
import {ErrorMessage} from './ErrorMessage';

@inject('vTerminal', 'global')
@observer
class PaymentMethodECheck extends React.Component {

    constructor(props) {
        super(props);
        this.handleTextChangeACH = this.handleTextChangeACH.bind(this);
        this.handleTextChangeACHSelect = this.handleTextChangeACHSelect.bind(this);
        this.getRowComponent = this.getRowComponent.bind(this);
    }

    async validateField(e){
        if(e){
            await this.handleTextChangeACH(e.target.name,e.target.value, true);
        }
        this.resizeContainer();
    }

    resizeContainer(){
        let config = this.props.config;
        window.parent.postMessage({
            event: "callback-payabli-function-resize"+config.randomId,
            data: document.body.scrollHeight
        }, '*');
    }

    handleTextChangeACH(field, value, blur) {
        this.props.vTerminal.handleTextChangeACH(field, value);
        let validators = this.props.global.validators;

        if (field === "achAccountHolderName") {
            if(!validators.isEmpty(value) && (blur === true || !validators.isMaxLength(250, value))){
                if(!validators.isEmpty(value) && !validators.isMaxLength(250, value))
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('achAccountHolderName',null);
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('achAccountHolderName',"Your account holder name is invalid.");
                }
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountHolderName',null);
            }
            
        }

        if (field === "achRouting") {
            if(!validators.isEmpty(value) && ((blur === true || !validators.isMaxLength(9, value)) && !validators.stringValidator('routing', value))){
                if(this.props.global.isValidRouting(value) && !validators.isEmpty(value) && validators.isLength(9, value) && !validators.stringValidator('routing', value))
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('achRouting',null);
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('achRouting',"Your routing number is invalid.");
                }
            }else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achRouting',null);
            }
            this.validateReAchRouting(this.props.vTerminal.paymentPage.paymentMethods.reAchRouting);
        }

        if (field === "reAchRouting" && blur === true ) {
            this.validateReAchRouting(value);
        }

        if (field === "achAccount") {

            if(!validators.isEmpty(value) && ((blur === true || !validators.isMinLength(4, value)) && !validators.isMaxLength(17, value) && !validators.stringValidator('numbers', value))){
                if(!validators.isEmpty(value) &&  !validators.stringValidator('numbers', value) && !validators.isMinLength(4, value) && !validators.isMaxLength(17, value))
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('achAccount',null);
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('achAccount',"Your account number is invalid.");
                }
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccount',null);
            }
            this.validateReAchAccount(this.props.vTerminal.paymentPage.paymentMethods.reAchAccount);
        }

        if (field === "reAchAccount" && blur === true ) {
            this.validateReAchAccount(value);
        }

        

        let globalConfig = this.props.config;
        if(globalConfig && globalConfig.randomId){
            if (!this.props.vTerminal.validateFieldsByMethod('ach', globalConfig)) {
                window.parent.postMessage({
                    event: "callback-payabli-function-ready"+ globalConfig.randomId,
                    data: ['ach', true]
                }, '*');
            }
            else {
                window.parent.postMessage({
                    event: "callback-payabli-function-ready"+ globalConfig.randomId,
                    data: ['ach', false]
                }, '*');
            }
        }
    }

    validateReAchRouting(value){
        if(value){
            if(value && (value !== "") && (value !== this.props.vTerminal.paymentPage.paymentMethods.achRouting)){
                this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchRoutingError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('reAchRouting',"The field doesn't match.");
            }
            else{
                this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchRoutingError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('reAchRouting',null);
            }
        }
    }

    validateReAchAccount(value){
        if(value){
            if(value && (value !== "") && (value !== this.props.vTerminal.paymentPage.paymentMethods.achAccount)){
                this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchAccountError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('reAchAccount',"The field doesn't match.");
            }
            else{
                this.props.vTerminal.setPaymentPageError('rePaymentMethodsAchAccountError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('reAchAccount',null);
            }
        }
    }
    
    
    handleTextChangeACHSelect(e) {
        this.props.vTerminal.handleTextChangeACH(e.target.name, e.target.value);
        let validators = this.props.global.validators;
        if (e.target.name === "achAccountType") {
            if(validators.isEmpty(e.target.value) || validators.isMaxLength(250, e.target.value))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountType',"Your account type is invalid.");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('achAccountType',null);
            }
        }

        let globalConfig = this.props.config;
        if(globalConfig && globalConfig.randomId){
            if (!this.props.vTerminal.validateFieldsByMethod('ach', globalConfig)) {
                window.parent.postMessage({
                    event: "callback-payabli-function-ready"+ globalConfig.randomId,
                    data: ['ach', true]
                }, '*');
            }
            else {
                window.parent.postMessage({
                    event: "callback-payabli-function-ready"+ globalConfig.randomId,
                    data: ['ach', false]
                }, '*');
            }
        }
    }

    getRowComponent(x) {
        let r = this.props.vTerminal.achRows;
        let rd = this.props.vTerminal.achInputs;
        var f = r[x];
        var rows = [];
        if (f.length > 0) {
            for (var i = 0; i < f.length; i++) {
                if (f[i] === 'achAccountHolderName') {
                    rows.push(
                        <div id="achAccountHolderNameContainer" className={"fullw-440 col-" + rd.achAccountHolderName.size + " mb-3"} key={i}>
                            {this.props.showPopoverError === true &&
                            <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.achAccountHolderName}/>
                            }
                            <div className={rd.achAccountHolderName.floating ? "form-floating" : ""}>
                                {rd.achAccountHolderName.floating === false &&
                                    <label htmlFor="achAccountHolderName">{rd.achAccountHolderName.label}</label>
                                }
                                <IMaskInput
                                    mask={this.props.global.maskValidator('alphanumericspaces')}
                                    name="achAccountHolderName"
                                    value={this.props.vTerminal.paymentPage.paymentMethods.achAccountHolderName ? this.props.vTerminal.paymentPage.paymentMethods.achAccountHolderName : ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeACH('achAccountHolderName', value)
                                    }
                                    onBlur={
                                        (e) => this.validateField(e)
                                    }
                                    placeholder={rd.achAccountHolderName.placeholder}
                                    className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchAccountHolderNameError ? "form-control input-error" : "form-control"}
                                    id="achAccountHolderName"
                                />
                                {rd.achAccountHolderName.floating === true &&
                                    <label htmlFor="achAccountHolderName">{rd.achAccountHolderName.label}</label>
                                }
                            </div>
                            {this.props.showPopoverError === false &&
                            <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.achAccountHolderName}/>
                            }
                        </div>
                    );
                }
                else if (f[i] === 'achAccountType') {
                    rows.push(
                        <div id="achAccountTypeContainer" className={"fullw-440 col-" + rd.achAccountType.size + " mb-3"} key={i}>
                            {this.props.showPopoverError === true &&
                            <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.achAccountType}/>
                            }
                            <div className={rd.achAccountType.floating ? "form-floating" : ""}>
                                {rd.achAccountType.floating === false &&
                                    <label htmlFor="achAccountType">{rd.achAccountType.label}</label>
                                }
                                <select value={this.props.vTerminal.paymentPage.paymentMethods.achAccountType}  defaultValue={''}  className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchAccountTypeError ? "form-select form-control input-error" : "form-select form-control"} id="achAccountType" name="achAccountType" onChange={(e) => this.handleTextChangeACHSelect(e)}>
                                    <option value="">{rd.achAccountType.placeholder}</option>
                                    {this.props.vTerminal.paymentPage.paymentMethods.methods.checking &&
                                        <option value="Checking">Checking</option>
                                    }
                                    {this.props.vTerminal.paymentPage.paymentMethods.methods.savings &&
                                        <option value="Savings">Savings</option>
                                    }
                                </select>
                                {rd.achAccountType.floating === true &&
                                    <label htmlFor="achAccountType">{rd.achAccountType.label}</label>
                                }
                            </div>
                            {this.props.showPopoverError === false &&
                            <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.achAccountType}/>
                            }
                        </div>
                    );
                }
                else if (f[i] === 'achRouting') {
                    rows.push(
                        <div id="achRoutingContainer" className={"fullw-440 col-" + rd.achRouting.size + " mb-3"} key={i}>
                            {this.props.showPopoverError === true &&
                            <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.achRouting}/>
                            }
                            <div className={rd.achRouting.floating ? "form-floating" : ""}>
                                {rd.achRouting.floating === false &&
                                    <label htmlFor="achRouting">{rd.achRouting.label}</label>
                                }
                                <IMaskInput
                                    mask={this.props.global.maskValidator('routing')}
                                    name="achRouting"
                                    value={this.props.vTerminal.paymentPage.paymentMethods.achRouting ? this.props.vTerminal.paymentPage.paymentMethods.achRouting : ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeACH('achRouting', value)
                                    }
                                    onBlur={
                                        (e) => this.validateField(e)
                                    }
                                    placeholder={rd.achRouting.placeholder}
                                    className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchRoutingError ? "form-control input-error" : "form-control"}
                                    id="achRouting"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                />
                                {rd.achRouting.floating === true &&
                                    <label htmlFor="achRouting">{rd.achRouting.label}</label>
                                }
                            </div>
                            {this.props.showPopoverError === false &&
                            <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.achRouting}/>
                            }

                            {rd.achRouting.confirm === true && 
                                <>
                                {this.props.showPopoverError === true &&
                                <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.reAchRouting}/>
                                }
                                <div className={rd.achRouting.floating ? "form-floating" : ""}>
                                    {rd.achRouting.floating === false &&
                                        <label htmlFor="reAchRouting" className='mt-3'>Repeat {rd.achRouting.label}</label>
                                    }
                                    <IMaskInput
                                        onPaste={(e)=>{e.preventDefault();}}
                                        mask={this.props.global.maskValidator('routing')}
                                        name="reAchRouting"
                                        value={this.props.vTerminal.paymentPage.paymentMethods.reAchRouting ? this.props.vTerminal.paymentPage.paymentMethods.reAchRouting : ''}
                                        unmask={true}
                                        onAccept={
                                            (value, mask) => this.handleTextChangeACH('reAchRouting', value)
                                        }
                                        onBlur={
                                            (e) => this.validateField(e)
                                        }
                                        placeholder={"Re-"+rd.achRouting.placeholder}
                                        className={(this.props.vTerminal.getPaymentPageErrors.rePaymentMethodsAchRoutingError ? "form-control input-error " : "form-control ") + (rd.achRouting.floating === false ? "" : "mt-3")}
                                        id="reAchRouting"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                    />
                                    {rd.achRouting.floating === true &&
                                        <label htmlFor="reAchRouting">Repeat {rd.achRouting.label}</label>
                                    }
                                </div>
                                {this.props.showPopoverError === false &&
                                <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.reAchRouting}/>
                                }
                                </>
                            }
                          
                        </div>
                    );
                }
                else if (f[i] === 'achAccount') {
                    rows.push(
                        <div id="achAccountContainer" className={"fullw-440 col-" + rd.achAccount.size + " mb-3"} key={i}>
                            {this.props.showPopoverError === true &&
                            <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.achAccount}/>
                            }
                            <div className={rd.achAccount.floating ? "form-floating" : ""}>
                                {rd.achAccount.floating === false &&
                                    <label htmlFor="achAccount">{rd.achAccount.label}</label>
                                }
                                <IMaskInput
                                    mask={this.props.global.maskValidator('accountnumber')}
                                    name="achAccount"
                                    value={this.props.vTerminal.paymentPage.paymentMethods.achAccount ? this.props.vTerminal.paymentPage.paymentMethods.achAccount : ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeACH('achAccount', value)
                                    }
                                    onBlur={
                                        (e) => this.validateField(e)
                                    }
                                    placeholder={rd.achAccount.placeholder}
                                    className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsAchAccountError ? "form-control input-error" : "form-control"}
                                    id="achAccount"
                                    maxLength={17}
                                />
                                {rd.achAccount.floating === true &&
                                    <label htmlFor="achAccount">{rd.achAccount.label}</label>
                                }
                            </div>
                            {this.props.showPopoverError === false &&
                            <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.achAccount}/>
                            }

                            {rd.achAccount.confirm === true && 
                                <>
                                {this.props.showPopoverError === true &&
                                <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.reAchAccount}/>
                                }
                                <div className={rd.achAccount.floating ? "form-floating" : ""}>
                                    {rd.achAccount.floating === false &&
                                        <label htmlFor="reAchAccount" className='mt-3'>Repeat {rd.achAccount.label}</label>
                                    }
                                    <IMaskInput
                                        onPaste={(e)=>{e.preventDefault();}}
                                        mask={this.props.global.maskValidator('accountnumber')}
                                        name="reAchAccount"
                                        value={this.props.vTerminal.paymentPage.paymentMethods.reAchAccount ? this.props.vTerminal.paymentPage.paymentMethods.reAchAccount : ''}
                                        unmask={true}
                                        onAccept={
                                            (value, mask) => this.handleTextChangeACH('reAchAccount', value)
                                        }
                                        onBlur={
                                            (e) => this.validateField(e)
                                        }
                                        placeholder={"Re-"+rd.achAccount.placeholder}
                                        className={(this.props.vTerminal.getPaymentPageErrors.rePaymentMethodsAchAccountError ? "form-control input-error " : "form-control ") + (rd.achAccount.floating === false ? "" : "mt-3")}
                                        id="reAchAccount"
                                        maxLength={17}
                                    />
                                    {rd.achAccount.floating === true &&
                                        <label htmlFor="reAchAccount">Repeat {rd.achAccount.label}</label>
                                    }
                                </div>
                                {this.props.showPopoverError === false &&
                                <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.reAchAccount}/>
                                }
                                </>
                            }
                        </div>
                    );
                }
            }
        }
        return (<>
            {rows}
            </>
        );
    }

    render() {
        return (
            <div>
                <div className="row">
                    {this.getRowComponent(0)}
                </div>
                <div className="row">
                    {this.getRowComponent(1)}
                </div>
                <div className="row">
                    {this.getRowComponent(2)}
                </div>
            </div>
        );
    }
}

export { PaymentMethodECheck };