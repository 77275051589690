import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PaymentMethod } from './views/PaymentMethod'
import { PaymentMethodEmbeded } from './views/PaymentMethodEmbeded'
import { ViewVirtualTerminal } from './views/ViewVirtualTerminal'
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import GlobalStore from './store/GlobalStore';
import VirtualTerminalStore from './store/VirtualTerminalStore';
import CustomerStore from './store/CustomerStore';
import ExpressCheckout from "./components/ExpressCheckout";


class App extends Component {
  render() {
    return (
      <Provider global={GlobalStore} vTerminal={VirtualTerminalStore} customer={CustomerStore}>
      <Router basename="">
        <Switch>
          <Route exact path="/:version/addpaymethod/:config" component={PaymentMethod}/>
          <Route exact path="/:version/addpaymethodembeded/:config" component={PaymentMethodEmbeded}/>
          <Route exact path="/:version/vterminal/:config" component={ViewVirtualTerminal}/>
          <Route exact path="/addpaymethod/:config" component={PaymentMethod}/>
          <Route exact path="/addpaymethodembeded/:config" component={PaymentMethodEmbeded}/>
          <Route exact path="/expresscheckout/:config" component={ExpressCheckout}/>
          <Route exact path="/vterminal/:config" component={ViewVirtualTerminal}/>
        </Switch>
      </Router>
      </Provider>
    )
  }

}

export default App;